import React from 'react'
import './Home.css';
import homeimg from '../../assets/heroImg.png';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <>

            <div className='home'>

                <motion.div
                    initial={{ x: "-5rem", y: "5rem", opacity: 0 }}
                    animate={{ x: 0, y: 0, opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }
                    }
                    className='homeText'
                >

                    <h1>XYZ Organisation</h1>
                    <div>

                        <TypeAnimation
                            sequence={[
                                // Same substring at the start will only be typed out once, initially
                                'Consult a doctor for ABC Disease',
                                2000, // wait 1s before replacing "Mice" with "Hamsters"
                                'Consult a doctor for XYZ Disease',
                                2000,
                                'Consult a doctor for DEF Disease',
                                2000,
                                'Consult a doctor for UVW Disease',
                                2000
                            ]}
                            wrapper="span"
                            speed={50}
                            style={{ fontSize: '2em', display: 'inline-block' }}
                            repeat={Infinity}
                            className='animated'
                        />


                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum nulla blanditiis non, eligendi harum laborum ipsam quae, neque beatae, sint dolorum ab magni accusantium velit. Aliquid corrupti illo sed maiores.</p>
                    </div>
                    <div className='buttons'>
                        <Link to="/signup">
                            <button className='btn signIn'>Sign Up</button>
                        </Link>
                        <Link to="/categories">
                            <button className='btn consultNow'>Consult Now <i class='bx bx-right-arrow-alt'></i></button>
                        </Link>
                    </div>

                </motion.div>

                <motion.div
                    initial={{ x: "5rem", y: "-5rem", opacity: 0 }}
                    animate={{ x: 0, y: 0, opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }
                    }
                    className='img'
                >
                    <img src={homeimg} alt="" />
                </motion.div>
            </div>
        </>
    )
}

export default Home