import React from 'react'
import { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Navbar.css'

const Navbar = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const location = useLocation();

  return (
    <>
      <div className="nav">
        <div className="navtext">
          <h3>logo</h3>
          <h3>Company</h3>
        </div>

        <div className="navlist">
          <Link to="/" className={`${location.pathname === '/' ? 'pathActive' : ''}`}>Home</Link>
          <Link to="/categories" className={`${location.pathname === '/categories' ? 'pathActive' : ''}`}>Categories</Link>
          <Link to="/about" className={`${location.pathname === '/about' ? 'pathActive' : ''}`}>About</Link>
          <Link to="/contact" className={`${location.pathname === '/contact' ? 'pathActive' : ''}`}>Contact Us</Link>
        </div>

        <div className={`iconMenu ${menuOpened === true ? 'hidden' : 'active'}`} onClick={() => setMenuOpened(true)}>
          <i class='bx bx-menu'></i>
        </div>


        <div className={`${menuOpened === true ? 'activeMenu' : 'menu'}`}>
          <div className="navlistPhone" onClick={() => setMenuOpened(false)}>
            <Link to="/" className={`${location.pathname === '/' ? 'pathActive' : ''}`}>Home</Link>
            <Link to="/categories" className={`${location.pathname === '/categories' ? 'pathActive' : ''}`}>Categories</Link>
            <Link to="/about" className={`${location.pathname === '/about' ? 'pathActive' : ''}`}>About</Link>
            <Link to="/contact" className={`${location.pathname === '/contact' ? 'pathActive' : ''}`}>Contact Us</Link>
          </div>

          <div className={`close ${menuOpened === true ? 'active' : 'hidden'}`} onClick={() => setMenuOpened(false)}>
            <i class='bx bx-x'></i>
          </div>
        </div>
      </div>


    </>
  )
}

export default Navbar