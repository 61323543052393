import React from 'react';
import './Category.css';

const Card = () => {
    return (
        <div className='categoryCard'>
            <div className="img">
                {/* <img src={bacteria} alt="" /> */}
            </div>

            <div className="cardText">
                <h1>XYZ Disease</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, laudantium?</p>
                <button className='btn'>Contact</button>
            </div>
        </div>
    )
}

const Category = () => {
    return (
        <div className='categorySection'>
            <div className="categories">
                <Card />
                <Card />
                <Card />
                <Card />
                <Card />
                <Card />
            </div>
        </div>
    )
}

export default Category