import { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import './Signup.css';

const Signup = () => {

    const [hiddenPart, setHiddenPart] = useState('signinpart');

    const handleButtonClick = (part) => {
        setHiddenPart(part);
    }

    return (
        <>
            <section className="sign-in-page">
                <div className="container">
                    <span className={`form__title ${hiddenPart === 'signuppart'?'hidden':''}`} id="signup-title">Sign up</span>
                    <form className={`form ${hiddenPart === 'signuppart'?'hidden':''}`} id="signup-form">
                        <div className="form__group">
                            <i className="ri-user-line form__icon"></i>
                            <input type="text" placeholder="Name" required className="form__input" />
                            <span className="form__bar"></span>
                        </div>
                        <div className="form__group">
                            <i className="ri-mail-line form__icon"></i>
                            <input type="text" placeholder="Email" required className="form__input" />
                            <span className="form__bar"></span>
                        </div>
                        <div className="form__group">
                            <i className="ri-lock-line form__icon"></i>
                            <input
                                type="password"
                                placeholder="Password"
                                required
                                className="form__input"
                            />
                            <span className="form__bar"></span>
                        </div>
                        <div className="form__group">
                            <i className="ri-lock-line form__icon"></i>
                            <input
                                type="password"
                                placeholder="Confirm password"
                                required
                                className="form__input"
                            />
                            <span className="form__bar"></span>
                        </div>
                        <button type="submit" className="form__button">Sign up</button>
                        <p className="form__switch ">
                            Already have an account? <Link className={`${hiddenPart === 'signuppart'?'hidden':''}`} id="signin-btn" onClick={() => handleButtonClick('signuppart')}>Login</Link>
                        </p>
                    </form>


                    <span className={`form__title ${hiddenPart === 'signinpart'?'hidden':''}`} id="signin-title">Sign In</span>
                    <form className={`form ${hiddenPart === 'signinpart'?'hidden':''}`} id="signin-form">
                        <div className="form__group">
                            <i className="ri-mail-line form__icon"></i>
                            <input type="text" placeholder="Email" required className="form__input" />
                            <span className="form__bar"></span>
                        </div>
                        <div className="form__group">
                            <i className="ri-lock-line form__icon"></i>
                            <input type="password" placeholder="Password" required className="form__input" />
                            <span className="form__bar"></span>
                        </div>
                        <button type="submit" className="form__button " >Sign in</button>
                        <p className="form__switch">
                            Don't have an account? <Link className={`${hiddenPart === 'signinpart'?'hidden':''}`} id="signup-btn" onClick={() => handleButtonClick('signinpart')}>Sign up</Link>
                        </p>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Signup;