import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from "./Components/Home/Home";
import Signup from "./Components/Signup/Signup";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Category from "./Components/Category/Category";

function App() {
  return (
    <Router >
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/categories" element={<Category/>}/>
        <Route exact path="/about" element={<About/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
        <Route exact path="/signup" element={<Signup/>}/>
      </Routes>
    </Router>
  );
}

export default App;