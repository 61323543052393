import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className='aboutSection'>
            <div className='aboutCtr'>
                <h1>About Us</h1>

                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat reiciendis corrupti similique quisquam ratione fuga, totam suscipit qui blanditiis veritatis unde beatae earum sit cum, distinctio laudantium! At dolor enim laboriosam, nam ratione optio harum, velit eligendi odio modi mollitia? Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est assumenda cum expedita animi earum quo, nihil voluptatem officiis necessitatibus, laudantium culpa atque, illo porro debitis optio ut quia ipsum natus sapiente alias numquam accusantium provident. Ducimus, obcaecati nam? Tempora tempore error soluta dolor. Tempore enim officiis hic ab ratione dolorem. Dolorum illo dicta error? Soluta eveniet consectetur aliquam sit ut.</p>
                <div className='buttons'>
                    <Link to="/">
                        <button className='btn'><i class='bx bx-left-arrow-alt'></i> Back</button>
                    </Link>
                    <Link to="/contact">
                        <button className='btn'>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default About