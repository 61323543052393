import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className='contactSection'>

            <section id="contact">
                <h2 className="heading">Contact <span>US!</span></h2>

                <form action="#">
                    <div className="inputbox">
                        <input type="text" name="name" placeholder="Full Name" />
                        <input type="email" name="email" placeholder="E-Mail" />
                    </div>
                    <div className="inputbox">
                        <input type="number" name="phone" placeholder="Mobile Number" />
                        <input type="text" name="emailsub" placeholder="E-Mail Subject" />
                    </div>
                    <textarea name="query" id="" cols="30" rows="10" placeholder="Youur Message"></textarea>
                </form>
                <input type="submit" value="Submit" className="btn" />
            </section>
        </div>
    )
}

export default Contact